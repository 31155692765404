import type { ReactElement } from 'react'
import { useHasPrivileges, useIsUserType, type ExtendedPrivilege } from '@/hooks'
import { ExperimentalBackground } from './components/ExperimentalBackground'

type ComponentProps = {
  exactPrivileges?: boolean
  children: ReactElement
  experimental?: boolean // Enable a yellow background on the whole component
  userTypes?: uui.domain.rm.RouteManagerUserType[]
  accountPrivileges?: ExtendedPrivilege[]
}

export function HasPrivileges(props: ComponentProps) {
  const { exactPrivileges, children, userTypes, experimental, accountPrivileges } = props

  const gotPrivileges = useHasPrivileges(accountPrivileges ?? [], exactPrivileges ?? false)

  const hasUserType = useIsUserType(userTypes ?? [])

  return gotPrivileges && hasUserType ? (
    experimental ? (
      <ExperimentalBackground>{children}</ExperimentalBackground>
    ) : (
      children
    )
  ) : null
}
